// 
// _pagination.scss
// 

// Pagination rounded (Custom)
.pagination-rounded {
  .page-link {
      border-radius: 30px !important;
      margin: 0 5px;
      border: none;
      width: 32px;
      height: 32px;
      padding: 0;
      text-align: center;
      line-height: 32px;
  }
}